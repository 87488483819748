import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
// import Peer from 'simple-peer';
import Peer from 'simple-peer/simplepeer.min.js';
import { VolumeOff, VolumeUp } from '@mui/icons-material';

const socket = io('wss://api.appcleanin.it', { transports: ['polling'] });

function InterfacciaOperatore() {
    const [streaming, setStreaming] = useState(false);
    const [message, setMessage] = useState('');
    const [peer, setPeer] = useState(null);
    const videoRef = useRef();
    const toggleStreamButtonRef = useRef();
    const [isMute, setIsMute] = useState(true);


    useEffect(() => {
        socket.on('streamStopped', (operatorId) => {
            stopStream();
            console.log('stopping stream')
        });
        socket.on('streamStarted', (operatorId) => {
            // toggleStream();
            console.log('Starting stream');
        });
    }, [streaming, peer]);

    const toggleStream = () => {
        if (streaming) {
            stopStream();
            socket.emit('stopStream', 2);
        } else {
            startStream();
        }
    };

    const startStream = () => {
        navigator.mediaDevices.getUserMedia({ video: true, audio: true })
            .then(stream => {
                // Display the local video stream
                videoRef.current.srcObject = stream;

                // Use the state's peer or create new one
                const newPeer = peer ?? new Peer({ initiator: true, trickle: false });

                if (!peer) {
                    setPeer(newPeer);
                }

                newPeer.addStream(stream);

                newPeer.on('signal', (data) => {
                    socket.emit('startStream', { operatorId: 2, signalData: data });
                });

                socket.on('streamSignalReturn', (data) => {
                    if (newPeer && !newPeer.destroyed) newPeer.signal(data.signalData)
                    else {
                        console.warn('Attempting to signal a destroyed peer');
                        stopStream();
                    }
                });

                setStreaming(true);

                // Gracefully stop the stream
                socket.on('disconnect', () => {
                    console.log('Initiator disconnected');
                    stopStream();
                });

                newPeer.on('close', () => {
                    console.log('Peer connection closed');
                    setPeer(null);
                    stopStream();
                });

                newPeer.on('error', () => {
                    console.log('Peer connection errored');
                    setPeer(null);
                    stopStream();
                });
            })
            .catch(err => console.error('Error accessing media devices.', err));
    };


    const sendMessage = () => {
        if (message.trim()) {
            socket.emit('message', { from: 'Operatore 2', text: message });
            setMessage('');
        }
    };

    const stopStream = () => {
        console.log('stopping stream..')
        videoRef.current.srcObject = null;
        if (streaming && peer) {
            try {
                peer.removeAllListeners();
                peer.destroy();
                resetState();
                window.location.reload()
            } catch (error) {
                console.warn('error while stopping stream', error)
            }
        }
        setStreaming(false);
    }

    const resetState = () => {
        setStreaming(false);
        setMessage('');
        setPeer(null);
        setIsMute(true);
    }

    const registerIntervention = () => {
        socket.emit('intervention', { operatorId: 2, text: 'Nuovo intervento registrato' });
    };

    return (
        <div>
            <h2>Interfaccia Operatore</h2>
            <div style={{ width: '80%', height: '80%' }} className='relative'>
                <video
                    ref={videoRef}
                    autoPlay={true}
                    muted={isMute}
                    playsInline={true}
                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                />
                <div className='bottom-right'>
                    <span className='' onClick={() => setIsMute((prev) => (!prev))}>
                        {
                            streaming && (
                                isMute
                                    ? <VolumeOff className='stream-icon' />
                                    : <VolumeUp className='stream-icon text-red' />
                            )
                        }
                    </span>
                </div>
            </div>
            <button ref={toggleStreamButtonRef} onClick={toggleStream}>{streaming ? 'Stop Stream' : 'Start Stream'}</button>
            <div>
                <input
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Scrivi un messaggio..."
                />
                <button onClick={sendMessage}>Invia</button>
            </div>
            <button onClick={registerIntervention}>Registra Intervento</button>
        </div>
    );
}


export default InterfacciaOperatore;