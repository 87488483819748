import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
// import Peer from 'simple-peer';
import Peer from 'simple-peer/simplepeer.min.js';
import { VolumeOff, VolumeUp } from '@mui/icons-material';

const socket = io('wss://api.appcleanin.it', { transports: ['polling'] });


function CentraleOperativa() {
  const operatorsData = [
    { id: 2, status: 'offline', streaming: false, peer: null, streamSettings: { muted: true } },
    { id: 3, status: 'offline', streaming: false, peer: null, streamSettings: { muted: true } },
    { id: 4, status: 'offline', streaming: false, peer: null, streamSettings: { muted: true } },
    { id: 5, status: 'offline', streaming: false, peer: null, streamSettings: { muted: true } }
  ];

  const [operators, setOperators] = useState(operatorsData);
  const [messages, setMessages] = useState([]);
  const [interventions, setInterventions] = useState([]);
  const videoRefs = useRef({});

  const videoRef1 = useRef(null);
  const [adminPeer, setAdminPeer] = useState(null);
  const [adminStreamIsMute, setAdminStreamIsMute] = useState(true);


  useEffect(() => {
    // Update operators online status
    socket.on('operatorStatus', ({ id, status }) => {
      setOperators(prev => prev.map(op => op.id === id ? { ...op, status } : op));
    });

    initializeStreamHandler();

    socket.on('streamStopped', (operatorId) => {
      stopStream(operatorId);
    });

    socket.on('message', (message) => {
      setMessages(prev => [...prev, message]);
    });

    socket.on('intervention', (intervention) => {
      setInterventions(prev => [...prev, intervention]);
    });

    return () => {
      socket.off('operatorStatus');
      socket.off('streamStarted');
      socket.off('streamSignal');
      socket.off('streamSignalReturn');
      socket.off('streamStopped');
      socket.off('message');
      socket.off('intervention');
    };
  }, []);

  const initializeStreamHandler = () => {
    socket.on('streamStarted', ({ operatorId, signalData }) => {
      setOperators(prev => prev.map(operator => {
        if (operator.id === operatorId) {
          // Get or create peer instance
          const peer = operator.peer ?? new Peer({ initiator: false, trickle: false });
          // const peer = adminPeer ?? new Peer({ initiator: false, trickle: false });


          if (peer && !peer.destroyed) peer.signal(signalData)
          else console.warn('Attempting to signal a destroyed peer')

          peer.on('signal', (data) => {
            if (peer && !peer.destroyed) {
              socket.emit('streamSignal', { signalData: data });
              console.log('peer.signal -> emit streamSignal')
            }
          })

          peer.on('stream', (stream) => {
            console.log('peer.on stream -> display the stream')
            // Assign the new MediaStream to the according video element
            const videoEl = document.getElementById(`video-stream-${operator.id}`);
            // const videoEl = document.getElementById(`videoStreamRef`);
            if (videoEl) {
              videoEl.srcObject = stream;
            }
          });

          // Gracefully stop the stream
          socket.on('disconnect', () => {
            console.log('socket.on disconnect -> stopStream')
            stopStream(operatorId);
          });

          peer.on('close', () => {
            console.log('Peer connection closed');
            stopStream(operatorId);
          });

          peer.on('error', (err) => {
            console.log('Peer connection errored', err);
            stopStream(operatorId);
          });

          return { ...operator, peer };
        }
        return operator;
      }));
    });
  }

  const toggleStream = (operatorId) => {
    const operator = operators.find(op => op.id === operatorId);
    if (operator.peer && !operator.peer.destroyed) {
      socket.emit('stopStream', operatorId);
    } else {
      stopStream(operatorId)
      socket.emit('startStream', operatorId);
    }
  };

  const sendMessage = (operatorId, message) => {
    socket.emit('message', { from: 'Centrale', to: operatorId, text: message });
  };

  const stopStream = (operatorId) => {
    console.log('stopping stream..')
    const operator = operators.find(op => op.id === operatorId);

    const videoEl = document.getElementById(`video-stream-${operatorId}`)
    // const videoEl = document.getElementById(`videoStreamRef`)
    const mediaStream = videoEl?.srcObject;
    if(mediaStream) {
      mediaStream.getTracks().forEach((track) => {
        track.stop();
      });
      videoEl.srcObject = null;
    }

    operator.peer?.removeAllListeners();
    operator.peer?.destroy();
    setOperators((prev) => prev.map(item => item.id != operatorId ? item : { ...item, peer: null, streamSettings: { muted: true } }));
    // adminPeer?.destroy();
    // setAdminPeer(null);
  }

  return (
    <div>
      <h2>Centrale Operativa</h2>
      <div style={{ width: '350px', display: 'none' }}>
        <video
          id='videoStreamRef'
          ref={videoRef1}
          autoPlay={true}
          muted={adminStreamIsMute}
          playsInline={true}
        // style={{ maxWidth: '100%', maxHeight: '100%' }}
        />
        <button onClick={() => setAdminStreamIsMute(!adminStreamIsMute)} >{adminStreamIsMute ? 'Unmute' : 'Mute'}</button>
        <button onClick={() => { stopStream(2); socket.emit('stopStream', 2) }} >{adminPeer && !adminPeer.destroyed ? 'Stop stream' : '--'}</button>
      </div>


      <div className="operator-grid">
        {operators.map(op => (
          <div key={op.id} className="operator-window">
            <h3>Operatore {op.id}</h3>
            <p>Stato: {op.status}</p>
            <div className='relative'>
              <video
                id={`video-stream-${op.id}`}
                ref={el => videoRefs.current[op.id - 2] = el}
                autoPlay={true}
                muted={op.streamSettings.muted}
                playsInline={true}
              />
              <div className='bottom-right'>
                <span onClick={() => { op.peer && !op.peer.destroyed && setOperators((prev) => prev.map(o => o.id !== op.id ? o : { ...o, streamSettings: { ...o.streamSettings, muted: !o.streamSettings.muted } })) }}>
                  {
                    op.streamSettings.muted
                      ? <VolumeOff className='stream-icon' />
                      : <VolumeUp className='stream-icon text-red' />
                  }
                </span>
              </div>
            </div>
            <button onClick={() => toggleStream(op.id)}>
              {op.peer && !op.peer.destroyed ? 'Stop Stream' : 'Avvia Stream'}
            </button>
            <input
              type="text"
              placeholder="Invia messaggio"
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  sendMessage(op.id, e.target.value);
                  e.target.value = '';
                }
              }}
            />
          </div>
        ))}
      </div>
      <div className="messages">
        <h3>Messaggi</h3>
        {messages.map((msg, index) => (
          <p key={index}>{msg.from} a {msg.to}: {msg.text}</p>
        ))}
      </div>
      <div className="interventions">
        <h3>Interventi</h3>
        {interventions.map((int, index) => (
          <p key={index}>Operatore {int.operatorId}: {int.text}</p>
        ))}
      </div>
    </div>
  );
}

export default CentraleOperativa;